@import "core";
@import "~bootstrap/scss/bootstrap";

:root {
  --gray-50: #F9FAFB;
  --gray-100: #F3F4F6;
  --gray-200: #E5E7EB;
  --gray-300: #D1D5DC;
  --gray-400: #99A1AF;
  --gray-500: #6A7282;
  --gray-600: #4A5565;
  --gray-700: #364153;
  --gray-800: #1E2939;
  --gray-900: #101828;
  --gray-950: #030712;

  --red-500: #fb2c36;
  --red-600: #e7000b;
  --red-700: #c10007;

  --green-500: #00bc7d;
  --green-600: #009966;
  --green-700: #007a55;

  --business-50: #F5F3FF;
  --business-100: #EDE9FE;
  --business-200: #DDD6FF;
  --business-300: #C4B4FF;
  --business-400: #A684FF;
  --business-500: #8E51FF;
  --business-600: #7F22FE;
  --business-700: #7008E7;
  --business-800: #5D0EC0;
  --business-900: #4D179A;
  --business-950: #2F0D68;
}

* {
  font-family: 'Roboto' !important;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
}

html, body, #root {
  margin: 0;
  padding: 0;
}

input::placeholder {
  opacity: 0.45 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

::selection {
  background-color: var(--business-200) !important;
}

[data-bs-theme="dark"] {
  ::selection {
    background: var(--business-800) !important;
  }
}
