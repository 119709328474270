@import "../../assets/styles/core";

.tradingViewWrapper {
  display: flex;
  height: 100svh;
}

.tradingViewWidget {
  height: 100svh;
  width: 100vw;

  iframe {
    height: 100%!important;
    width: 100vw;
  }
}