@import "../../assets/styles/core";

.calculator_wrapper {
  height: 100%;
  overflow: hidden;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
  }
}