$blue: #894bff;
$red: #fb2c36;
$green: #00bc7d;

$gray-50: #F9FAFB !default;
$gray-100: #F3F4F6 !default;
$gray-200: #E5E7EB !default;
$gray-300: #D1D5DC !default; // dark color text
$gray-400: #99A1AF !default;
$gray-500: #6A7282 !default;
$gray-600: #4A5565 !default;
$gray-700: #364153 !default;
$gray-800: #1E2939 !default;
$gray-900: #101828 !default;
$gray-950: #030712 !default;


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import 'bootstrap/scss/mixins';
@import 'bootstrap-icons/font/bootstrap-icons';