@import "../../assets/styles/core";

.video {
  height: 100vh;
  width: 100vw;
  position: relative;

  &__background {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @media (max-width: 992px) and (orientation: landscape) {
      display: none;
    }
  }
}

.login {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  &__logo {
    user-select: none;
  }

  &__label {
    font-size: 20px;
  }

  &__card {
    width: 500px;
    border-radius: 20px;

    @include media-breakpoint-down(sm) {
      width: 100vw;
      box-shadow: none!important;
    }

    @media (max-width: 992px) and (orientation: landscape) {
      width: 60vw;
      box-shadow: none!important;
      margin-top: 5rem!important;
    }
  }

  &__buttonContainer {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 128px;
  }
}